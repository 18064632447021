* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}
p {
  font-size: 14px;
}
/*=========================== All Theme colors start ======================= */
:root {
  --primColor: #ffffff;
  --secColor: #3366ff;
  --secHovColor: #3366ffb2;
  --themeColor: #17263a;
  --lgthemeColor: #bbcbfb;
  --lgthemeColor2: #9ba5ca;
  --white: #fff;
  --gray: gray;
  --primHover: #e91d3598;
  --black: #000000;
  --lgGray: #aab3cc;
  --boxBg: #f1f4fb;
  --red: #f7284a;
  --blue: #3366ff;
  --orange: #fe7f00;
  --green: #0dcd94;
  --borderColor: #d3dfea;
}
/* background colors */
.bgBlue {
  background-color: var(--blue);
}
.bgRed {
  background-color: var(--red);
}
.bgGreen {
  background-color: var(--green);
}
.bgOrange {
  background-color: var(--orange);
}
.bgLgGray {
  background-color: var(--lgGray);
}
/* Colors Classes */
.colRed {
  color: var(--red);
}
.colBlue {
  color: var(--blue);
}
.colBlack {
  color: var(--black) !important;
}
.colGray {
  color: var(--lgGray);
}
.colWhite {
  color: var(--white) !important;
}
.text-bold {
  font-weight: 600;
}
/* =========== Calender Css stat here  ============ */
.mainCalender {
  width: 100%;
  border: none;
  background-color: var(--white);
}
.mainCalender .react-calendar__navigation button {
  color: var(--blue);
  font-weight: bold;
  margin: 0px 10px;
  border: 1px solid var(--lgGray);
}
.mainCalender .react-calendar__tile:enabled:hover,
.mainCalender .react-calendar__tile:enabled:focus,
.mainCalender .react-calendar__navigation button:enabled:hover,
.mainCalender .react-calendar__navigation button:enabled:focus {
  background-color: var(--blue);
  color: var(--white);
  font-weight: 600;
}
.mainCalender .react-calendar__navigation__label__labelText--from {
  font-size: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));
}
.mainCalender button {
  border-radius: 5px;
}
.mainCalender .react-calendar__navigation button {
  min-width: 30px;
  height: 30px;
}
label {
  font-size: 14px;
  font-weight: 600;
  line-height: 2;
}
/* ========================= All Theme colors Ends ================= */
/* ================================================================== */
/*                           Typography                               */
/* ================================================================== */
.primHeading {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: var(--black);
  text-transform: capitalize;
  font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
}
.subHeading,
.sectionHeading {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: var(--black);
  text-transform: capitalize;
  font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
}
.smText {
  font-weight: 500;
  color: var(--lgGray);
  font-size: 15px;
}
.subHeading {
  font-size: calc(11px + (15 - 11) * ((100vw - 320px) / (1920 - 320)));
}
.subHeading-sm {
  font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
}
.primHeading span {
  color: var(--lgGray);
}
.exsmText {
  font-weight: 500;
  font-size: 11px;
}
.dashboard-anatlytics h3 {
  font-family: 'Poppins', sans-serif;
  font-size: calc(15px + (18 - 15) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 600;
  text-transform: capitalize;
}
.dashboard-anatlytics h4 {
  font-family: 'Poppins', sans-serif;
  font-size: calc(17px + (20 - 17) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 500;
  margin-top: 15px;
  text-transform: uppercase;
}
.date_time,
.analyticsIcon {
  padding: 10px;
  color: var(--primColor);
  width: 45px;
  height: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 20px;
}
.analyticsWrap1 h4 {
  color: var(--green);
}
.analyticsWrap1 .analyticsIcon {
  background-color: var(--green);
}
.analyticsWrap2 h4 {
  color: var(--blue);
}
.analyticsWrap2 .analyticsIcon {
  background-color: var(--blue);
}
.analyticsWrap3 h4 {
  color: var(--orange);
}
.analyticsWrap3 .analyticsIcon {
  background-color: var(--orange);
}
.analyticsWrap4 h4 {
  color: var(--red);
}
.analyticsWrap4 .analyticsIcon {
  background-color: var(--red);
}
.resize-none {
  resize: none;
}
/* Theme css & Header start here  */
.siteWrapper {
  position: relative;
}
.topBar {
  background: var(--primColor);
  min-height: 5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1024;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px 0px 0px;
  box-shadow: 0px 6px 18px rgb(196 205 224 / 10%);
}
.mobileMenu {
  display: none;
}
.sideNav {
  position: fixed;
  top: 5rem;
  width: 280px;
  height: calc(100% - 4rem);
  z-index: 98;
  background: var(--themeColor);
  transition: 0.2s linear;
  color: var(--white);
  visibility: visible;
  overflow-y: scroll;
}
.sideNav .sidebar-menu-nav {
  margin-bottom: 20%;
}
.navLink {
  border: none;
  border-radius: 5px;
  color: #fff;
  display: block;
  font-size: 13px;
  font-weight: 400;
  margin: 15px 0;
  outline: none;
  padding: 12px 15px;
  position: relative;
  text-align: left;
  text-transform: capitalize;
  width: 100%;
}
.navIco {
  width: 20px;
}
.subNavLink {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navLink.active {
  background-color: var(--secColor);
}
.navLink:hover {
  background-color: var(--secHovColor);
  color: var(--primColor);
}
.sidebar-menu-nav-icon .sidebar-menu-nav-title {
  left: 45px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
  white-space: nowrap;
}
.removeSideBar .sidebarNavTitle {
  background-color: #fff0;
  color: #fff;
  display: none;
  opacity: 0;
  padding: 0 10px;
  transition: 0.3s ease-in-out;
  white-space: nowrap;
}
.removeSideBar .configuration {
  margin-left: 70px;
}
.subLink {
  display: block;
  padding: 5px 5px;
  font-size: 12px;
  color: white;
}
.subLink:hover {
  color: var(--secColor) !important;
}
.headerLogo {
  object-fit: cover;
  object-position: center;
  width: 100px;
  border-radius: 0px;
}
.LogoWrapper {
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  display: flex;
  height: 5rem;
  justify-content: center;
  width: 280px;
}
.userInfo {
  text-align: center;
  margin: 30px 0px;
  color: var(--lgGray);
}

.userInfo h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
}

.userInfo span {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}

.userAvatar {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;
  border: 2px solid var(--bs-blue);
}

.activeDot {
  position: absolute;
  display: block;
  background-color: var(--blue);
  width: 15px;
  height: 15px;
  right: 5px;
  bottom: -15px;
  border-radius: 100%;
}

.mainContent {
  margin-top: 5rem;
  transition: 0.2s linear;
  background: var(--white);
}
.configuration {
  margin-left: 280px;
  background: var(--boxBg);
  padding: 3rem 1.5rem 0rem 1.5rem;
  width: 100%;
  transition: 0.2s linear;
  overflow-x: hidden;
  min-height: 100vh;
}

.userName {
  align-items: center;
  display: flex !important;
  gap: 5px;
  justify-content: space-between;
}

.userName .avatar {
  border-radius: 99px;
  height: 40px;
  width: 40px;
}

.userName span {
  color: var(--black);
  display: inline-block;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 100px;
  font-size: 12px;
  text-transform: capitalize;
}

.removeSideBar .LogoWrapper {
  background-color: var(--primColor);
}
.removeSideBar .nav_wrapper li .nav_links {
  font-size: 20px;
  padding: 15px 0px;
  justify-content: center;
  position: relative;
}
.removeSideBar .nav_links span {
  opacity: 0;
  position: absolute;
  display: inline-block;
  background-color: #f33f53;
  z-index: 1024;
  padding: 15px 20px;
  font-size: 15px;
  bottom: 0;
  top: 0;
  left: 0;
  transition: 0.5s linear;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}
.removeSideBar .nav_links:hover span {
  opacity: 1;
  left: 60px;
}
.removeSideBar .nav_wrapper li .nav_links {
  font-size: 20px;
  padding: 15px 0px;
  justify-content: center;
}
.nav_links {
  display: flex;
  align-items: center;
  color: var(--white) !important;
}
.nav_wrapper {
  list-style-type: none;
  padding-left: 0;
}
.removeSideBar .sideNav {
  transition: 0.2s linear;
  width: 70px;
}
.removeSideBar .sideNav .userInfo {
  display: none;
}
.nav_wrapper li .nav_links {
  padding: 15px 20px;
  margin: 15px 0;
  /* transition: 1.5s ease-in-out; */
}
li .nav_links.active {
  background-color: var(--primColor);
  color: var(--white);
}
.nav_wrapper li:hover {
  background-color: var(--primColor);
}
.nav_wrapper li:hover .nav_links {
  color: var(--white) !important;
}
.hamburger {
  align-items: center;
  background-color: #f1f4fb;
  background-color: var(--boxBg);
  border: none;
  border-radius: 10px;
  color: #000;
  color: var(--black);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  height: 40px;
  justify-content: center;
  padding: 5px;
  text-align: center;
  transition: 0.5s ease-in-out;
  width: 40px;
}
.mobileMenu li {
  list-style-type: none;
}
.hamIcon {
  font-size: 20px;
}
.hamburger:hover {
  background-color: var(--secColor);
  color: var(--white);
}
.customDropDown {
  background-color: transparent;
  border: none;
  padding: 0;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
}
.customDropDown.active,
.customDropDown:focus,
.customDropDown:hover {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.customDropDown::after {
  display: none !important;
}
.dropNav {
  display: block;
  padding: 5px 10px;
  margin: 5px 0;
}
.notifBox {
  transform: translate(-50%, 53px) !important;
  min-width: 300px;
  padding-bottom: 0;
  left: auto !important;
  right: auto !important;
}
.notifBox_wrap {
  z-index: 9024;
  max-height: 330px;
  overflow-y: scroll;
}
.drop_bottom {
  background-color: var(--primColor);
  color: var(--white) !important;
  width: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 5px;
  text-align: center;
}
.drop_bottom:hover {
  background-color: var(--primHover);
}
.sideMenu {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.sideMenu li {
  margin: 0 10px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.box {
  background: var(--white);
  border-radius: 15px;
  box-shadow: 0 0 6px #e0e0e0;
  overflow: hidden !important;
  padding: 20px;
}
/* Table Css start */
.customTable {
  border-spacing: 0px 30px !important;
  padding: 0 10px;
  border-collapse: separate;
  margin: 30px 0px;
}
.customTable tr {
  background-color: transparent;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}
.customTable th,
.customTable td {
  padding: 1.2rem 1rem !important;
  vertical-align: middle;
  color: var(--black);
  font-size: 13px;
}
.customTable2 th,
.customTable2 td {
  border: 1px solid #aab3cc8a;
  text-align: center;
  white-space: nowrap;
  text-align: center;
  padding: 10px 20px !important;
}
/* Table Css end */
/*Profile pages css  */
.avatarWrap {
  width: 135px;
  height: 135px;
}
.profileAvatar {
  width: 100%;
  border-radius: 100%;
  height: auto;
}
/* Theme css ends here  */
.primary-heading {
  font-size: 40px;
  color: var(--secColor);
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
.secondary-heading {
  font-family: 'Open Sans', sans-serif;
  font-size: 25px;
  font-weight: 400;
  text-transform: capitalize;
}
/* ======================== Auth Pages =============================== */
.authWrapper {
  min-height: 100vh;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../images/bg1.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-color: #bacaf9;
}
.authBox {
  width: 500px;
  background-color: var(--white);
  z-index: 99;
  border-radius: 10px;
  padding: 50px 30px;
}
.authBox label {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  line-height: 2;
  text-transform: capitalize;
}
.socialWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialWrap a {
  width: 30px;
  height: 30px;
  background-color: var(--secColor);
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-radius: 100%;
  color: var(--white);
  transition: 0.5s ease-in-out;
}
.socialWrap a:hover {
  color: var(--primColor);
  background-color: var(--secHovColor);
}
.authBox .main-btn {
  padding: 7px 15px 7px 15px;
}
.sec-btn,
.main-btn {
  outline: none;
  border: none;
  background-color: var(--secColor);
  color: var(--white);
  padding: 10px 15px 10px 15px;
  margin: 5px 0;
  border-radius: 7px;
  font-size: 13px;
  font-weight: 500;
  min-width: 150px;
  text-align: center;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  text-transform: capitalize;
}
.main-btn:hover {
  background-color: var(--secHovColor);
  color: var(--white);
}
.sec-btn {
  background-color: var(--red);
  color: var(--white);
}
.sec-btn:hover {
  background-color: var(--orange);
}
.right-side {
  background-image: url('../images/adminbg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  background-position: center;
}
.inpIcons,
.show-password {
  left: 0;
  top: 49%;
  font-size: 16px;
  color: var(--lgthemeColor2);
  background: var(--white);
  outline: none;
  transform: translateY(-50%);
  padding: 0px 12px;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1.5px solid var(--borderColor);
  z-index: 999;
}
.filterSelect,
.prim-date,
.primary-inputs {
  padding: 8px 10px 8px 50px;
  outline: none;
  border-radius: 5px;
  color: #1e1e1e;
  width: 100%;
  font-size: 15px;
  border: 1px solid var(--borderColor);
}
.filterSelect,
.prim-date {
  padding: 7px 10px 8px 8px;
  font-size: 14px;
}
.primary-inputs:focus,
.primary-inputs:focus {
  outline: var(--blue);
  border: 1px solid var(--blue);
}
.primary-inputs::placeholder {
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: var(--lgGray);
}
.primary-inputs:disabled {
  background-color: transparent;
}
.disableInputs {
  border: none;
  padding: 10px 0;
  background-color: transparent;
}
a {
  text-decoration: none !important;
  /* color: black !important; */
}
a:hover {
  text-decoration: none !important;
}
.primLink {
  color: var(--blue) !important;
  transition: 0.5s linear;
  text-decoration: underline !important;
}
.primLink:hover {
  color: var(--black) !important;
}
.input-type-code {
  width: 100%;
  height: 125px;
  margin-right: 10px;
  outline: none;
  border-radius: 15px;
  border: 1px solid var(--primColor);
  font-size: 80px;
  color: var(--primColor);
  text-align: center;
}
.input-type-code::-webkit-input-placeholder {
  color: var(--primColor);
}
.input-type-code:-ms-input-placeholder {
  color: var(--primColor);
}
.input-type-code::-ms-input-placeholder {
  color: var(--primColor);
}
.input-type-code::placeholder {
  color: var(--primColor);
}
section.authentication-wrapper {
  overflow-x: hidden;
}
input[type='date'],
select {
  cursor: pointer;
}
select {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('../images/caret.png') no-repeat 95% center !important;
}
.dashboard-inputs {
  padding: 10px 15px !important;
  color: #263871;
  background-color: transparent;
  background-image: none;
  border-color: #eceef7;
  box-shadow: 0 0px 10px -5px #e8ebf2;
  min-width: 85px;
}
.primSelect {
  background: url('../images/caret.png') no-repeat 95% center !important;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.dashboard-inputs:focus {
  outline: none !important;
}
.primary-input-icon {
  right: 15px;
  top: 50%;
  font-size: 15px;
  cursor: pointer;
  transform: translateY(-50%);
  color: var(--lgGray);
}
.dashboard-input-box {
  max-width: 326px !important;
}
.back {
  font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
}
.dashboard-anatlytics {
  background-color: var(--primColor);
  border-radius: 10px;
  margin: 10px 0;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(196 205 224 / 20%);
  border-radius: 13px;
  padding: 20px 15px;
}
.dashboard-anatlytics span .icons {
  font-size: 26px;
  color: var(--black);
}
.charts-wrap img {
  width: 100%;
  margin: 0 auto;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.notification-bell {
  width: 70px;
  height: 60px;
  background-color: var(--primColor);
  color: var(--white);
  font-size: 35px;
  border-radius: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
}
.notification-text p {
  font-family: 'Open Sans, sans-serif';
  font-size: 20px;
  color: #666666;
  font-weight: 300;
}
.notificationText {
  word-break: break-word;
  white-space: break-spaces;
}
.notification-text .date {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Poppins, sans-serif';
  margin-right: 10px;
  margin-left: 10px;
}
.rotate {
  -webkit-animation: rotate 1.5s alternate infinite;
  animation: rotate 1.5s alternate infinite;
}
/* =========== side scrollbar ============= */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: var(--lgGray) !important;
}
::-webkit-scrollbar-thumb {
  background: var(--blue);
  border-radius: 15px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--blue);
}
::-moz-selection {
  background-color: var(--lgGray);
  color: #000;
}
::selection {
  background-color: var(--lgGray);
  color: #000;
}
/* Remove input type number arrows  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
/* Modal css */
.modal-cancel {
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: none;
  outline: none;
  background-color: var(--red);
  color: #fff;
  height: 25px;
  width: 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.modal-cancel:hover {
  background-color: var(--orange);
}
.modalContent {
  padding: 30px;
  text-align: center;
}
.modalPrimHeading {
  font-family: 'Open Sans';
  color: var(--black);
  text-transform: capitalize;
  font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
}
.modalContent img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  object-position: center;
  margin: 10px 0px;
  border-radius: 100%;
}
input[type='search'],
input[type='date'],
select {
  font-size: 14px;
}
/* ================ home page styles  ======================= */
.chartLabels {
  border: 1px solid var(--gray);
  margin: 0px 10px;
  padding: 10px 15px;
  color: #263871;
  background-color: transparent;
  background-image: none;
  border-color: #eceef7;
  box-shadow: 0 0px 10px -5px #e8ebf2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tdLabel,
.chartLabels span {
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.date_time {
  font-size: 12px;
  font-weight: bold;
  height: 55px;
  width: 55px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.date_time span {
  display: block !important;
}
.borderCenter {
  border-left: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
}
.attendenceAnalytics {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.attendenceAnalytics h6 {
  font-weight: 600;
  font-size: 15px;
  font-family: sans-serif;
}
.attendenceAnalytics .date_time {
  height: 40px;
  width: 40px;
}
.actionIcon {
  display: flex;
  width: 35px;
  height: 35px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 5px;
  margin: 0px 5px;
  color: var(--boxBg);
  border: none;
}
.actionIcon:hover {
  color: var(--boxBg);
}
/* ======================== Leaves page css ============================= */
.customBadge {
  padding: 2px 15px;
  font-size: 11px;
  font-family: 'Open Sans';
  font-weight: 600;
  color: var(--white);
  border-radius: 6px;
}
.redborder {
  border: 1px solid red;
}
/* ================ Pagination Css starts      ======================= */
.pagination-container {
  display: flex;
  list-style-type: none;
}
.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.customPagination .page-link {
  padding: 5px 13px 5px 13px !important;
}
.customPagination li.page-item .page-link:hover,
.customPagination li.page-item.active .page-link {
  background-color: var(--secColor);
  color: var(--white) !important;
  border-color: inherit;
}
.customPagination li.page-item .page-link,
.customPagination li.page-item .page-link:focus {
  box-shadow: none;
  border-radius: 0;
}
/* ================ pagination css ends ======================= */
.selectdata {
  padding: 5px;
  border-radius: 0;
  outline: none;
  border: 1px solid var(--lgGray);
  min-width: 50px;
}
.comming_holidays {
  padding-left: 0;
  height: 350px;
  overflow-y: scroll;
  padding-right: 20px;
}
.comming_holidays .date {
  font-size: 20px;
  line-height: 1;
}
.comming_holidays .month {
  font-size: 10px;
  text-transform: uppercase;
}
.comming_holidays p {
  color: var(--lgGray);
  font-weight: 500;
  font-size: 12px;
}
.profileCardWrap {
  text-align: center;
}
.profileBg {
  position: relative;
}
.profileCardWrap .userInfo {
  width: 150px;
  margin: 0 auto;
}
.profileCardWrap .activeDot {
  right: 40px;
  bottom: 20px;
}
.profileCardWrap h5 {
  font-size: 15px;
  font-weight: 800;
  font-family: sans-serif;
}
.profileCardWrap h6 {
  font-size: 13px;
  font-weight: 500;
  color: var(--gray);
}
.smIconWrap {
  width: 35px;
  height: 35px;
  margin: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 15px;
  background-color: var(--lgGray);
  border-radius: 5px;
  transition: 0.5s linear;
}
.smIconWrap:hover {
  background-color: var(--blue);
  color: var(--white);
}
.tdAvatar {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  margin-right: 10px;
  object-fit: cover;
  object-position: center;
}
/* ====================== Task pages ==================== */
.taskTopBar {
  background-color: var(--blue);
  padding: 15px 30px;
}
.viewableScreen {
  margin: 30px 0px;
  border-radius: 0;
}
.taskBody {
  padding: 15px 30px;
}
.textBox {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin: 10px 0px;
}
.textBox label {
  flex-shrink: 0;
  text-transform: capitalize;
}
.textBox p {
  word-break: break-word;
  font-family: 'Open Sans';
  text-transform: capitalize;
}
.tox .tox-statusbar {
  visibility: hidden !important;
}
.editor .tox-tinymce {
  border-radius: 0 !important;
}
.editor {
  padding: 10px 20px;
}
.formUploadBtn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 11px;
  font-weight: bold;
  height: 100%;
  background-color: var(--secColor);
  color: var(--white);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid var(--blue);
  cursor: pointer !important;
}
.summernote {
  font-size: 14px;
  font-family: 'Open Sans';
}
/* Custom Radio Buttons */
/* The radioWrap */
.radioWrap {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default radio button */
.radioWrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.chkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #17263a82;
  border-radius: 50%;
}
/* On mouse-over, add a grey background color */
.radioWrap:hover input ~ .chkmark {
  background-color: #ccc;
}
/* When the radio button is checked, add a blue background */
.radioWrap input:checked ~ .chkmark {
  background-color: #2196f3;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.chkmark:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.radioWrap input:checked ~ .chkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.radioWrap .chkmark:after {
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
}
/* =============== Custom Tabs ================ */
.customTabs .nav-item {
  margin: 10px 10px 20px 0px;
}
.customTabs .nav-link {
  color: var(--black);
  background-color: #aab3cc3d;
  border-radius: 99px;
  font-size: 13px;
  font-family: 'Open Sans';
  font-weight: 600;
}
.customTabs .nav-link.active {
  color: var(--white) !important;
  background-color: var(--blue);
  font-weight: 800;
}
.uploadBtn {
  min-width: auto;
  border-radius: 100%;
  padding: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-size: 22px;
}
/* ========= Footer Css =========== */
/* .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
} */
.footer p {
  font-size: 13px;
  font-weight: 600;
}
.actionDropDown {
  padding: 5px;
}
.actionDropDown .dropdown-menu {
  padding: 0;
  font-size: 12px;
}
.actionDropDown .drop_item {
  font-weight: 600;
  font-family: 'Open Sans';
  padding: 5px 10px;
  margin: 2px 0px;
  text-align: center;
  transition: 0.5s ease-in-out;
}
.actionDropDown .drop_item:hover {
  background-color: var(--blue);
  color: var(--primColor);
}
.customFilters .main-btn {
  min-width: auto;
}
.searchInp {
  padding: 7px 10px 8px 8px;
  font-size: 14px;
  border: 1px solid var(--borderColor);
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
.searchInp:focus {
  border: 1px solid var(--borderColor);
}
.gototopAnim {
  animation: gototop 1.2s linear infinite alternate-reverse;
}
.top-btn {
  font-size: 20px;
  width: 45px;
  height: 45px;
  color: #fff;
  border-radius: 10px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #3366ff;
  position: absolute;
  bottom: 0;
  right: 20px;
  transition: 0.5s ease-in-out;
}
.top-btn:hover {
  background-color: transparent;
  border: 2px solid #3366ff;
  color: #3366ff;
}
.goTop {
  position: absolute;
  bottom: 30px;
  right: 20px;
  z-index: 9999;
}
.loading-spinner {
  animation: spinner 1.5s linear infinite;
}
.main-btn:disabled {
  background-color: #b9cbfa;
  cursor: not-allowed;
}
.present {
  color: green;
}
.halfDay {
  color: #ff6a00f0;
}
.absent {
  color: red;
}
.holiday {
  color: gold;
}
.noteLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}
.noteLabel svg,
.noteLabel i {
  font-size: 18px;
}
.noteLabel p {
  font-weight: 600;
  font-size: 12px;
}
.note {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes gototop {
  0% {
    transform: translateY(-0.5rem);
  }
  100% {
    transform: translateY(0.5rem);
  }
}
/* ======================================== */
/* ========   |  Media Queries  |  ======== */
/* ======================================== */
@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 991px) {
  .analyticsBox {
    padding: 10px;
  }
  .overflow-lg-hidden {
    overflow-x: scroll;
  }
  .configuration {
    margin-left: 70px !important;
    width: 100% !important;
  }
  .LogoWrapper {
    background-color: var(--primColor);
  }
  .sideNav .userInfo {
    display: none;
  }
  .sideNav {
    width: 0;
    visibility: hidden;
  }
  .configuration {
    margin-left: 0 !important;
  }
  .removeSideBar .sideNav {
    width: 250px;
    display: block;
    visibility: visible;
  }
  .removeSideBar .sidebarNavTitle {
    display: block;
    opacity: 1;
  }
  .sideNav .navLink:hover .sidebar-menu-nav-title {
    background-color: transparent;
    min-width: auto;
  }
  .primary-inputs {
    font-size: 13px;
  }
  .bordCenter:after {
    display: none;
  }
  .mobileFunctionalNav .configuration {
    margin-left: 0;
    width: 100%;
  }
  .mobileFunctionalNav .sideNav {
    width: 0 !important;
    visibility: hidden !important;
  }
}
@media screen and (max-width: 768px) {
  .md_none {
    display: none;
  }
  .smBlock {
    display: block !important;
  }
  .mobileMenu {
    transition: 0.5s linear;
    position: absolute;
    bottom: -75px;
    background-color: var(--themeColor);
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    left: 0;
    right: 0;
    padding: 10px 10px;
    color: var(--white);
  }
  .customDropDown {
    padding: 0px 10px;
  }
  .topBar {
    padding: 0 15px 0px 35px;
  }
}
@media screen and (max-width: 575px) {
  .w_md_100 {
    width: 100%;
  }
  .dashboard-inputs {
    width: 100%;
  }
  .input-type-code {
    height: 80px;
    font-size: 50px;
  }
  .authBox {
    padding: 25px;
  }
  .attendenceAnalytics h6 {
    font-size: 12px;
  }
  .dashboard-anatlytics {
    min-height: auto;
  }
  .primary-inputs,
  .primary-inputs::placeholder {
    font-size: 13px;
  }
  .sideNav {
    width: 0;
    visibility: hidden;
  }
  .configuration {
    padding: 2rem 0.3rem 0rem 0.3rem;
  }
  .removeSideBar .sideNav {
    width: 250px;
    position: absolute;
    display: block;
    visibility: visible;
  }
  .removeSideBar .sidebarNavTitle {
    display: block;
    opacity: 1;
  }
}
